import { PortfolioType } from '@src/entities/portfolio/model/Portfolio';
import { Country } from '@shared/model';
const PORTFOLIO_OVERVIEW_DATA = {
    type: PortfolioType.Margin,
    name: 'Binance Lounge',
    chat_activity: 0,
    copiers_count: 1600,
    id: '4',
    min_balance: 50.00,
    favorited_count: 7,
    followers: {
        count: 120,
        average_followers_monthly_return: 14.00,
        average_followers_monthly_return_first: 123.31,
        average_followers_monthly_return_last: 23.65,
        preview_followers: [],
    },
    parent_exchange_id: '4',
    referral_asset: 'usdt',
    traders_balance: 24.23,
    shared_count: 23,
    maxDrawdown: {
        daily: -2.23,
        monthly: -12.23,
        weekly: -23.13,
        yearly: -24.34,
    },
    user_subscription_summary: {
        queue_position: 1454,
        duration: 5,
        past_month_difference: 15.23,
        unrealized_pl_value: 14.63,
        unrealized_pl_percentange: 4.8,
        unrealized_pl_trend: 'up',
        unrealized_pl_currency: '$',
        realized_pl_value: 1324.23,
        realized_pl_currency: '$',
        realized_pl_trend: 'up',
        realized_pl_percentange: 5.63,
        subscription_date: new Date('December 17, 2023'),
    },
    viewed: 431,
    min_balance_currency: '$',
    minimum_balance_type: 'fifth_part',
    monthly_following_fee: 24.02,
    monthly_following_fee_currency: '$',
    graph_data: [],
    logo: '',
    owner_profile_id: '4',
    profile_id: '5',
    visibility: 'public',
    past_month_difference: 13.42,
    overall_difference: 4.02,
    past_ninety_days_difference: 14.23,
    past_week_difference: 5.45,
    traders_balance_currency: '$',
    whole_period_days_count: 23.23,
    past_month_start_date: '2023-11-15T13:09:56.276376+00:00',
    past_month_end_date: '2023-10-15T14:09:56.276376+00:00',
    trades: {
        averageHolding: 54,
        maxTradesMonthly: 23,
        minTradesMonthly: 13,
        profit: 34.23,
        total: 1530,
        weekTotal: 25,
        yearTotal: 1230,
    },
    is_archived: false,
    is_in_assessment_review: false,
    has_error: false,
    profile: {
        id: '4',
        userId: '1',
        username: 'rob_fox_trader',
        displayName: '',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sollicitudin sit rhoncus ornare nunc vel ipsum eget aasdf Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sollicitudin sit rhoncus ornare nunc vel ipsum eget aasdf',
        location: Country.RUSSIA,
        createdAt: new Date('2023-12-06T10:16:31.932166+00:00').toISOString(),
        type: 'TRADER',
        contacts: [],
        portfolio: [],
        followersCount: 0,
        followingCount: 0,
        profilePicture: {
            avatar: '',
            thumbnail: '',
            original: '',
        },
        isVerifiedTrader: false,
        exchangeConnectionProfile: [],
    },
};
const TRADER_INFO = {
    name: 'Tony Cash',
    profit: { currency: 'USDT', percentage: 14.76, value: 75.34 },
    assets: { currency: 'USDT', value: 1500.23 },
    user: 'trader',
};
const USER_INFO = {
    name: 'Jony Cash',
    profit: { currency: 'USDT', percentage: 12.76, value: 45.34 },
    assets: { currency: 'USDT', value: 12000.43 },
    user: 'you',
};
export { PORTFOLIO_OVERVIEW_DATA, TRADER_INFO, USER_INFO };
